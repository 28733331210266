import { useEffect } from "react";
import { atom, useAtom } from "jotai";
import { useSWRConfig } from "swr";
import { WSLogMessage, Organization } from "../../utils/types";
import { DatabaseVO } from "../../utils/types";
import { useSession } from "@supabase/auth-helpers-react";

export type WebSocketStatus = "connecting" | "connected" | "disconnected";

// Module-level singleton
let ws: WebSocket | null = null;
let reconnectTimeout: NodeJS.Timeout | null = null;

export const WebSocketProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [, setMessages] = useAtom(wsMessagesAtom);
  const [, setWsConnection] = useAtom(wsConnectionAtom);
  const [, setStatus] = useAtom(wsStatusAtom);
  const { mutate } = useSWRConfig();
  const session = useSession();

  // Helper function to update organization data in cache
  const updateOrgInCache = async (
    new_data: Organization,
    vo_changes: WSLogMessage["vo_changes"]
  ) => {
    await mutate(
      (key: any) => key[0] === "/admin" && key[1].type === "orgs",
      (current: any) => {
        console.log("new_data", new_data);
        if (!current?.data?.data) return current;

        const updatedData = {
          ...current,
          data: {
            ...current.data,
            data: current.data.data.map((org: any) => {
              if (org.id === new_data.id) {
                return {
                  ...new_data,
                  vos: [
                    ...new_data.vos,
                    ...org.vos.filter(
                      (v: DatabaseVO) =>
                        !vo_changes?.removed
                          .map((r) => r.vo_title)
                          .includes(v.vo_title)
                    ),
                  ],
                };
              }
              return org;
            }),
          },
        };

        console.log("updatedData", updatedData);

        return updatedData;
      },
      {
        revalidate: false,
        populateCache: true,
      }
    );
  };

  useEffect(() => {
    const setupWs = () => {
      // If already connected or connecting, do nothing
      if (
        ws?.readyState === WebSocket.OPEN ||
        ws?.readyState === WebSocket.CONNECTING
      ) {
        setStatus(
          ws.readyState === WebSocket.OPEN ? "connected" : "connecting"
        );
        setWsConnection(ws);
        return;
      }

      // Clear any existing connection
      if (ws) {
        ws.close();
        ws = null;
      }

      if (reconnectTimeout) {
        clearTimeout(reconnectTimeout);
        reconnectTimeout = null;
      }

      setStatus("connecting");
      ws = new WebSocket(`${process.env.REACT_APP_WS_URL}/ws`, [
        "soap",
        session!.access_token,
      ]);
      setWsConnection(ws);

      ws.onopen = () => {
        setStatus("connected");
      };

      ws.onclose = () => {
        setStatus("disconnected");
        setWsConnection(null);
        ws = null;

        if (!reconnectTimeout) {
          // TODO: TEMPORARY DISABLED
          reconnectTimeout = setTimeout(() => {
            reconnectTimeout = null;
            setupWs();
          }, 5000);
        }
      };

      ws.onerror = () => {
        ws?.close();
      };

      ws.onmessage = (event) => {
        const message: WSLogMessage = JSON.parse(event.data);
        if (message.org_name) {
          const orgName = message.org_name as string;
          setMessages((prev) => ({
            ...prev,
            [orgName]: [...(prev[orgName] || []), message],
          }));

          // Update SWR cache when receiving org_complete messages with vo_changes
          if (message.type === "org_complete" && message.new_data) {
            // Create an async function and immediately invoke it
            (async () => {
              try {
                if (message.success) {
                  await updateOrgInCache(message.new_data!, message.vo_changes);
                }
              } catch (error) {
                console.error("Error updating org in cache:", error);
              }
            })();
          }
        }

        if (message.type === "complete") {
          setMessages((prev) => {
            const newMessages = { ...prev };
            Object.keys(newMessages).forEach((orgName) => {
              newMessages[orgName] = [...newMessages[orgName], message];
            });
            return newMessages;
          });
        }
      };
    };
    if (session?.access_token) {
      setupWs();
    }
    return () => {
      if (reconnectTimeout) {
        clearTimeout(reconnectTimeout);
        reconnectTimeout = null;
      }

      if (ws) {
        ws.close();
        ws = null;
      }
    };
  }, [setMessages, setWsConnection, setStatus, session]);

  return <div>{children}</div>;
};

// Create atoms for WebSocket state
export const wsMessagesAtom = atom<{ [key: string]: WSLogMessage[] }>({});
export const wsConnectionAtom = atom<WebSocket | null>(null);
export const wsStatusAtom = atom<WebSocketStatus>("disconnected");

// Hooks
export const useOrgMessages = (orgName: string) => {
  const [messages] = useAtom(wsMessagesAtom);
  return messages[orgName] || [];
};

export const useWebSocketStatus = () => {
  const [status] = useAtom(wsStatusAtom);
  return status;
};
