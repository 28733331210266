import {
  Box,
  Button,
  Paper,
  Typography,
  CircularProgress,
  Checkbox,
  TablePagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useEffect, useState, useCallback } from "react";
import { fetcher } from "../../utils/fetchData";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Tag } from "@mui/icons-material";
import { debounce } from "../../utils/utils";
import { Organization } from "../../utils/types";
import useSWR, { useSWRConfig } from "swr";
import { OrgRow } from "../../components/admin/OrgRow";
import { SearchBar } from "../../components/admin/SearchBar";
import { WebSocketStatusIndicator } from "../../components/admin/WebSocketStatusIndicator";
import { ScrapeAllDialog } from "../../components/admin/ScrapeAllDialog";
import { CsvUploadDialog } from "../../components/admin/CsvUploadDialog";
import AddEditModal from "../../components/admin/AddEditModal";
import type {
  OrgFormData,
  VOFormData,
} from "../../components/admin/AddEditModal";
import { FilterBar, Filters } from "../../components/admin/FilterBar";
import { useAdmin } from "../../utils/hooks";
import { Stats } from "../../components/admin/Stats";
import { SortControls, SortOptions } from "../../components/admin/SortControls";

// Constants
const PAGE_SIZE = 100;
const DEFAULT_DISPLAY_SIZE = 100;

const getInitialData = (type: "org" | "vo", id: string, org: Organization) => {
  if (type === "org") {
    return {
      org_name: org.org_name,
      domain_url: org.domain_url,
      org_causes: org.org_causes,
      org_desc: org.org_desc,
      logo_url: org.logo_url,
    };
  } else {
    const vo = org.vos.find((v) => v.id === id);
    if (!vo) return null;
    return {
      vo_title: vo.vo_title,
      vo_description: vo.vo_description,
      vo_min_age: vo.vo_min_age,
      vo_skills: vo.vo_skills,
      vo_url: vo.vo_url,
      locations: vo.locations.map((loc) => ({
        street_address: loc.street_address,
        city: loc.city,
        zip_code: loc.zip_code,
        state: loc.state,
        county: loc.county,
      })),
    };
  }
};

const Manage = () => {
  const [pagination, setPagination] = useState({
    page: 1,
    displaySize: DEFAULT_DISPLAY_SIZE,
  });

  const [modalState, setModalState] = useState({
    open: false,
    type: "org" as "org" | "vo",
    editId: undefined as string | undefined,
    editOrgId: undefined as string | undefined,
    editOrgName: undefined as string | undefined,
    data: null as OrgFormData | VOFormData | null,
  });

  const [searchQuery, setSearchQuery] = useState("");
  const [scrapeState, setScrapeState] = useState({
    jobId: null as string | null,
    confirmOpen: false,
  });
  const [selectedOrgs, setSelectedOrgs] = useState<Set<string>>(new Set());
  const [csvUploadOpen, setCsvUploadOpen] = useState(false);

  const { mutate } = useSWRConfig();
  const selectedCount = selectedOrgs.size;

  const [filters, setFilters] = useState<Filters>({
    state: null,
    failedScrape: false,
    startDate: null,
    endDate: null,
  });

  const [sort, setSort] = useState<SortOptions>({
    sortBy: "org_name",
    sortOrder: "asc",
  });

  // Simplified handlers
  const handleClearCache = () =>
    mutate(() => true, undefined, { revalidate: true });

  const handleScrape = async (type: "all" | "selected") => {
    try {
      if (type === "all") {
        await fetcher<{ body: null }, { message: string }>(
          "POST",
          "/scrape/all",
          null,
          process.env.REACT_APP_SCRAPE_API_URL
        );
      } else {
        await fetcher<{ body: { org_ids: string[] } }, { message: string }>(
          "POST",
          "/scrape/selected",
          { org_ids: Array.from(selectedOrgs) },
          process.env.REACT_APP_SCRAPE_API_URL
        );
      }
      setScrapeState((prev) => ({ ...prev, jobId: type }));
    } catch (error) {
      console.error(`Failed to start scrape ${type}:`, error);
    }
  };

  // Simplified pagination calculation
  const { data, isLoading } = useAdmin({
    type: "orgs",
    pagination: { page: pagination.page, pageSize: PAGE_SIZE },
    searchQuery,
    filters,
    sort,
  });

  const displayOrgs = data?.data.data.slice(0, pagination.displaySize) ?? [];
  const total = data?.data.total ?? 0;

  // Simplified selection handlers
  const handleSelectOrg = useCallback((id: string, selected: boolean) => {
    setSelectedOrgs((prev) => {
      const next = new Set(prev);
      selected ? next.add(id) : next.delete(id);
      return next;
    });
  }, []);

  const handleSelectAll = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSelectedOrgs(
        new Set(
          event.target.checked
            ? displayOrgs.map((org: Organization) => org.id)
            : []
        )
      );
    },
    [displayOrgs]
  );

  // Clear selections and reset page when filters change
  useEffect(() => {
    setSelectedOrgs(new Set());
    setPagination((prev) => ({ ...prev, page: 1 }));
  }, [filters, searchQuery]);

  // Simplified search with debounce
  const debouncedSearch = useCallback(
    debounce((query: string) => {
      setSearchQuery(query);
      setPagination((prev) => ({ ...prev, page: 1 }));
    }, 300),
    []
  );

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  // Simplified modal handlers
  const handleEdit = (
    type: "org" | "vo",
    id: string,
    orgId: string,
    orgName: string
  ) => {
    const org = displayOrgs.find((o: Organization) =>
      type === "org" ? o.id === id : o.id === orgId
    );
    if (!org) return;

    const initialData = getInitialData(type, id, org);
    if (!initialData) return;

    setModalState({
      open: true,
      type,
      editId: id,
      editOrgId: orgId,
      editOrgName: orgName,
      data: initialData as OrgFormData | VOFormData,
    });
  };

  const handleAdd = (type: "org" | "vo", orgId?: string, orgName?: string) => {
    setModalState({
      open: true,
      type,
      editId: undefined,
      editOrgId: orgId,
      editOrgName: orgName,
      data: null,
    });
  };

  return (
    <Box sx={{ p: 3 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 3,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <Typography variant="h5">Manage Organizations</Typography>
          <WebSocketStatusIndicator />
        </Box>
        <Box sx={{ display: "flex", gap: 2 }}>
          <Button variant="outlined" color="primary" onClick={handleClearCache}>
            Refresh Data
          </Button>
          {selectedCount > 0 && (
            <Button
              variant="contained"
              color="secondary"
              onClick={() => handleScrape("selected")}
              startIcon={<RefreshIcon />}
              disabled={isLoading}
            >
              Rescrape Selected ({selectedCount})
            </Button>
          )}
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleAdd("org")}
          >
            Add New Organization
          </Button>
        </Box>
      </Box>

      <Box sx={{ mb: 3 }}>
        <Box sx={{ display: "flex", gap: 2, mb: 2 }}>
          <Box sx={{ flex: 1, display: "flex", gap: 2 }}>
            <SearchBar onSearch={debouncedSearch} />
            <FilterBar filters={filters} onFiltersChange={setFilters} />
          </Box>
          <SortControls sort={sort} onSortChange={setSort} />
        </Box>
      </Box>
      <Stats
        filter={{
          type: "orgs",
          pagination: { page: pagination.page, pageSize: PAGE_SIZE },
          searchQuery,
          filters,
          sort,
        }}
      />
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox" />
              <TableCell padding="checkbox">
                <Checkbox
                  indeterminate={
                    selectedCount > 0 && selectedCount < displayOrgs.length
                  }
                  checked={
                    displayOrgs.length > 0 &&
                    selectedCount === displayOrgs.length
                  }
                  onChange={handleSelectAll}
                />
              </TableCell>
              <TableCell />
              <TableCell>Organization</TableCell>
              <TableCell>Causes</TableCell>
              <TableCell>VOs</TableCell>
              <TableCell>Created</TableCell>
              <TableCell>Updated</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <TableRow>
                <TableCell colSpan={9} sx={{ border: 0 }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      py: 3,
                    }}
                  >
                    <CircularProgress size={40} />
                  </Box>
                </TableCell>
              </TableRow>
            ) : (
              displayOrgs.map((org: Organization) => (
                <OrgRow
                  key={org.id}
                  org={org}
                  onEdit={handleEdit}
                  onAdd={handleAdd}
                  scrapeAllJobId={scrapeState.jobId}
                  selected={selectedOrgs.has(org.id)}
                  onSelect={handleSelectOrg}
                />
              ))
            )}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={total}
          page={pagination.page - 1}
          onPageChange={(_, newPage) =>
            setPagination((prev) => ({ ...prev, page: newPage + 1 }))
          }
          rowsPerPage={pagination.displaySize}
          rowsPerPageOptions={[25, 50, 100]}
          onRowsPerPageChange={(e) => {
            const newSize = parseInt(e.target.value, 10);
            setPagination({ page: 1, displaySize: newSize });
          }}
        />
      </TableContainer>

      <AddEditModal
        open={modalState.open}
        onClose={() => setModalState((prev) => ({ ...prev, open: false }))}
        type={modalState.type}
        id={modalState.editId}
        orgId={modalState.editOrgId}
        orgName={modalState.editOrgName}
        initialData={modalState.data ?? undefined}
      />

      <ScrapeAllDialog
        open={scrapeState.confirmOpen}
        onClose={() =>
          setScrapeState((prev) => ({ ...prev, confirmOpen: false }))
        }
        onConfirm={() => handleScrape("all")}
      />

      <CsvUploadDialog
        open={csvUploadOpen}
        onClose={() => setCsvUploadOpen(false)}
      />
    </Box>
  );
};

export default Manage;
