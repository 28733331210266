import {
  Box,
  Button,
  Typography,
  IconButton,
  Collapse,
  Checkbox,
  Chip,
  Alert,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { useState, memo } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { useSWRConfig } from "swr";
import { ScrapeButton } from "./ScrapeButton";
import { ScrapeProgress } from "./ScrapeProgress";
import { StatusIndicator } from "./ScrapeProgress";
import { DeleteDialog } from "./DeleteDialog";
import { fetcher } from "../../utils/fetchData";
import { Organization } from "../../utils/types";

interface OrgRowProps {
  org: Organization;
  onEdit: (type: "org" | "vo", id: string, orgId: string, orgName: string) => void;
  onAdd: (type: "org" | "vo", orgId?: string, orgName?: string) => void;
  scrapeAllJobId: string | null;
  selected: boolean;
  onSelect: (id: string, selected: boolean) => void;
}

const ExpandableText = ({ text }: { text: string }) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <Typography
      variant="body2"
      sx={{
        maxWidth: "500px",
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        WebkitLineClamp: expanded ? "unset" : 1,
        WebkitBoxOrient: "vertical",
        cursor: "pointer",
        "&:hover": {
          opacity: 0.8,
        },
      }}
      onClick={() => setExpanded(!expanded)}
    >
      {text}
    </Typography>
  );
};

const formatDate = (dateString: string | null) => {
  if (!dateString) return "N/A";
  return new Date(dateString).toLocaleDateString("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });
};

export const OrgRow = memo(
  ({ org, onEdit, onAdd, scrapeAllJobId, selected, onSelect }: OrgRowProps) => {
    const [open, setOpen] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [deleteVODialogOpen, setDeleteVODialogOpen] = useState<string | null>(
      null
    );
    const [imageError, setImageError] = useState(false);
    const { mutate } = useSWRConfig();

    const handleDelete = async (type: "org" | "vo", id: string) => {
      // Optimistically update the UI
      mutate(
        (key: any) => key[0] === "/admin" && key[1].type === "orgs",
        (currentData: any) => {
          if (!currentData) return currentData;

          const updatedData = {
            ...currentData,
            data: {
              ...currentData.data,
              data:
                type === "vo"
                  ? currentData.data.data.map((o: Organization) => {
                      if (o.id === org.id) {
                        return {
                          ...o,
                          vos: o.vos.filter((v) => v.id !== id),
                        };
                      }
                      return o;
                    })
                  : currentData.data.data.filter(
                      (o: Organization) => o.id !== id
                    ),
            },
          };

          return updatedData;
        },
        { revalidate: false }
      );

      try {
        const response = await fetcher<never, { success: boolean }>(
          "DELETE",
          `/admin?type=${type}&id=${id}`
        );

        if (!response.success) {
          throw new Error(
            `Failed to delete ${type === "org" ? "organization" : "volunteer opportunity"}`
          );
        }

        if (type === "vo") {
          setDeleteVODialogOpen(null);
        } else {
          setDeleteDialogOpen(false);
        }
      } catch (err) {
        console.error(`Error deleting ${type}:`, err);
        mutate(
          (key: any) => key[0] === "/admin" && key[1].type === "orgs",
          undefined,
          { revalidate: true }
        );
      }
    };

    return (
      <>
        <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
          <TableCell>
            <IconButton size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell padding="checkbox">
            <Checkbox
              checked={selected}
              onChange={(e) => onSelect(org.id, e.target.checked)}
            />
          </TableCell>
          <TableCell padding="checkbox">
            <StatusIndicator
              orgName={org.org_name}
              lastLog={org.logs[0] ?? null}
            />
          </TableCell>
          <TableCell>
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
              {org.logo_url && !imageError ? (
                <Box
                  component="img"
                  src={org.logo_url}
                  alt={`${org.org_name} logo`}
                  loading="lazy"
                  sx={{
                    width: 40,
                    height: 40,
                    objectFit: "contain",
                    borderRadius: 1,
                  }}
                  onError={() => setImageError(true)}
                />
              ) : (
                <Box
                  sx={{
                    width: 40,
                    height: 40,
                    bgcolor: "action.hover",
                    borderRadius: 1,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "text.secondary",
                  }}
                  component={"img"}
                  src={"../images/lacy-dog-square.jpg"}
                />
              )}
              <Box>
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <Typography variant="body1">{org.org_name}</Typography>
                </Box>
                <Typography variant="body2" color="text.secondary">
                  <Link
                    href={org.domain_url}
                    target="_blank"
                    rel="noopener"
                    sx={{
                      textDecoration: "none",
                      color: "inherit",
                      "&:hover": {
                        textDecoration: "underline",
                      },
                    }}
                  >
                    {org.domain_url}
                  </Link>
                </Typography>
              </Box>
            </Box>
          </TableCell>
          <TableCell>
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {org.org_causes.map((cause) => (
                <Typography
                  key={cause}
                  variant="caption"
                  sx={{
                    bgcolor: "action.hover",
                    px: 1,
                    py: 0.5,
                    borderRadius: 1,
                  }}
                >
                  {cause}
                </Typography>
              ))}
            </Box>
          </TableCell>
          <TableCell>{org.vos.length}</TableCell>
          <TableCell>
            <Typography variant="caption" color="text.secondary">
              {formatDate(org.created_at)}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography variant="caption" color="text.secondary">
              {formatDate(org.updated_at)}
            </Typography>
          </TableCell>
          <TableCell align="right">
            <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}>
              <ScrapeButton orgId={org.id} />
              <Button
                color="primary"
                onClick={() => onEdit("org", org.id, org.id, org.org_name)}
              >
                Edit
              </Button>
              <IconButton
                color="default"
                onClick={() => setDeleteDialogOpen(true)}
                size="small"
              >
                <DeleteIcon />
              </IconButton>
            </Box>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ mx: 1, my: 2 }}>
                <Box sx={{ mb: 3 }}>
                  <Typography variant="h6" gutterBottom component="div">
                    Organization Details
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ mb: 2 }}
                  >
                    {org.org_desc}
                  </Typography>
                </Box>

                {(scrapeAllJobId || open) && (
                  <Box sx={{ mb: 3 }}>
                    <Typography variant="h6" gutterBottom component="div">
                      Logs
                    </Typography>
                    <ScrapeProgress
                      jobId={scrapeAllJobId || "single"}
                      orgName={org.org_name}
                      previousLogs={org.logs}
                    />
                  </Box>
                )}

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mb: 2,
                  }}
                >
                  <Typography variant="h6" component="div">
                    Volunteer Opportunities
                  </Typography>
                  <Box sx={{ display: "flex", gap: 1 }}>
                    <Button
                      variant="contained"
                      startIcon={<AddIcon />}
                      onClick={() => onAdd("vo", org.id, org.org_name)}
                      size="small"
                    >
                      Add Volunteer Opportunity
                    </Button>
                  </Box>
                </Box>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Title</TableCell>
                      <TableCell>Description</TableCell>
                      <TableCell>Skills</TableCell>
                      <TableCell>Min Age</TableCell>
                      <TableCell>Locations</TableCell>
                      <TableCell align="right">Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {org.vos.map((vo) => (
                      <TableRow key={`${org.id}-${vo.id}`}>
                        <TableCell>{vo.vo_title}</TableCell>
                        <TableCell>
                          <ExpandableText text={vo.vo_description} />
                        </TableCell>
                        <TableCell>
                          <Box
                            sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                          >
                            {vo.vo_skills.map((skill) => (
                              <Typography
                                key={skill}
                                variant="caption"
                                sx={{
                                  bgcolor: "action.hover",
                                  px: 1,
                                  py: 0.5,
                                  borderRadius: 1,
                                }}
                              >
                                {skill}
                              </Typography>
                            ))}
                          </Box>
                        </TableCell>
                        <TableCell>{vo.vo_min_age || "None"}</TableCell>
                        <TableCell>
                          {vo.locations.map((loc) => (
                            <Typography key={loc.id} variant="body2">
                              {[loc.city, loc.state, loc.zip_code]
                                .filter(Boolean)
                                .join(", ")}
                            </Typography>
                          ))}
                        </TableCell>
                        <TableCell align="right">
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "flex-end",
                              gap: 1,
                            }}
                          >
                            <Button
                              size="small"
                              color="primary"
                              onClick={() => onEdit("vo", vo.id, org.id, org.org_name)}
                            >
                              Edit
                            </Button>
                            <IconButton
                              size="small"
                              color="default"
                              onClick={() => setDeleteVODialogOpen(vo.id)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
        <DeleteDialog
          open={deleteDialogOpen}
          title="Delete Organization"
          content={`Are you sure you want to delete ${org.org_name}? This action cannot be undone.`}
          onConfirm={() => handleDelete("org", org.id)}
          onCancel={() => setDeleteDialogOpen(false)}
        />
        <DeleteDialog
          open={!!deleteVODialogOpen}
          title="Delete Volunteer Opportunity"
          content="Are you sure you want to delete this volunteer opportunity? This action cannot be undone."
          onConfirm={() => handleDelete("vo", deleteVODialogOpen!)}
          onCancel={() => setDeleteVODialogOpen(null)}
        />
      </>
    );
  }
);
